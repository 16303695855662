import { createRouter,createWebHistory} from 'vue-router'


const routes = [
  { path: '/',  component: () => import('../pages/Home.vue'), },
  { path: '/leden-administratie',  component: () => import('../pages/LedenAdministratie.vue'), },
  { path: '/algemene-leden-vergaderingen', component: () => import('../pages/AlgemeneLedenVergadering.vue'), },
  { path: '/evenementen', component: () => import('../pages/EvenementenBestuur.vue'), },
  { path: '/werkgroepen-en-kascommissie',  component: () => import('../pages/WerkgroepKascommissie.vue'), },
  { path: '/contact', component: () => import('../pages/ContactBestuur.vue'), },
]


const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router;
